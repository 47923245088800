<template>
    <div :id="elem_id" @dblclick="select" :class="[minimized ? 'drag_item' : 'drag_item_maximized ']"
         style="position: fixed; z-index: 10;">
        <div v-if="minimized">{{ elem_id }}</div>

        <div class="vizualization_container" style="height: 100%; width: 100%; background-color: white;"
             v-show="!minimized">
            <div class="vizualization_chart" style="z-index: 10000;">
                <div :id="elem_id + '_dataviz'"></div>
            </div>
            <div class="vizualization_settings">
                <select class="select" v-model="vizualizationType" @click.stop>

                    <option v-if="pointFeatureOn">Default</option>
                    <option
                        v-if="pointFeatureOn && reflectanceCurveAvailable">
                        Reflectance curve
                    </option>
                    <option
                        v-if="areaFeaturesOn && scatterPlotAvailable">
                        Histogram
                    </option>
                    <option
                        v-if="areaFeaturesOn">
                        Scatter plot
                    </option>

                </select>

                <button @click="$store.commit('map_store/DELETE_GRAPH_DATA', this.elem_id)" class="vizualization_btn">
                    Delete
                </button>

                <div v-if="vizualizationType === 'Scatter plot'">
                    <br>
                    <span>X-axis:
                    <select :id="'select_scatter'" class="select" v-model="scatterPlotX" @click.stop>
                        <option
                            v-for="key in Object.keys(this.$store.getters['map_store/graphData'].get(this.elem_id).data)"
                            :disabled="scatterPlotY === key">
                            {{ key }}
                        </option>
                    </select></span>
                    <br>
                    <span>Y-axis:
                    <select :id="'select_scatter'" class="select" v-model="scatterPlotY" @click.stop>
                        <option
                            v-for="key in Object.keys(this.$store.getters['map_store/graphData'].get(this.elem_id).data)"
                            :disabled="scatterPlotX === key">
                            {{ key }}
                        </option>
                    </select></span>
                </div>

                <span v-if="pointFeatureOn">
                    <hr>
                    <p class="vizualization_section_hdr">Coordinates</p>
                    <p><b>Lat</b> {{ this.$store.getters['map_store/graphData'].get(this.elem_id).info.coordinates[0] }}</p>
                    <p><b>Lon</b> {{ this.$store.getters['map_store/graphData'].get(this.elem_id).info.coordinates[1] }}</p>
                </span>

                <span v-if="areaFeaturesOn">
                    <hr>
                    <p class="vizualization_section_hdr">BBOX Coordinates</p>
                    <p><b>Min X:</b> {{
                            this.$store.getters['map_store/graphData'].get(this.elem_id).info.minValues[0]
                        }}</p>
                    <p><b>Min Y:</b> {{
                            this.$store.getters['map_store/graphData'].get(this.elem_id).info.minValues[1]
                        }}</p>
                    <p><b>Max X:</b> {{
                            this.$store.getters['map_store/graphData'].get(this.elem_id).info.maxValues[0]
                        }}</p>
                    <p><b>Max Y:</b> {{
                            this.$store.getters['map_store/graphData'].get(this.elem_id).info.maxValues[1]
                        }}</p>
                    <hr>
                    <p><b>Area</b> {{ this.$store.getters['map_store/graphData'].get(this.elem_id).info.area }} ㎢</p>

                </span>

            </div>

        </div>
    </div>

</template>

<script>
import * as d3 from "d3";

export default {
    // Credit: https://www.kirupa.com/html5/drag.htm
    name: "DraggableMenu",
    props: {
        "elem_id": String,
    },
    data() {
        return {
            minimized: false,
            vizualizationType: this.pointFeatureOn ? "Default" : "Histogram",
            scatterPlotX: "",
            scatterPlotY: "",
        }
    },
    computed: {
        reflectanceCurveAvailable() {
            return this.$store.getters['map_store/graphData'].get(this.elem_id) && this.$store.getters['map_store/graphData'].get(this.elem_id).data.length > 1;
        },
        scatterPlotAvailable() {
            return this.$store.getters['map_store/graphData'].get(this.elem_id) && Object.keys(this.$store.getters['map_store/graphData'].get(this.elem_id).data).length > 1;
        },
        pointFeatureOn() {
            return this.$store.getters['map_store/graphData'].get(this.elem_id) && this.$store.getters['map_store/graphData'].get(this.elem_id).type === 'point_feature';
        },
        areaFeaturesOn() {
            return this.$store.getters['map_store/graphData'].get(this.elem_id) && this.$store.getters['map_store/graphData'].get(this.elem_id).type === 'area_feature';
        }
    },
    watch: {
        scatterPlotX: function (newVal, oldVal) {
            if (oldVal === "") {
                return;
            }
            this.init_d3_scatterplot_graph();
        },
        scatterPlotY: function (newVal, oldVal) {
            if (oldVal === "") {
                return;
            }
            this.init_d3_scatterplot_graph();
        },

        vizualizationType: function (newVal, _) {
            if (newVal === "Default") {
                this.init_d3_barplot_graph();
            }
            if (newVal === "Reflectance curve") {
                this.init_d3_reflectance_curve_graph();
            }
            if (newVal === "Histogram") {
                this.init_d3_histogram_graph();
            }
            if (newVal === "Scatter plot") {
                let keys = Object.keys(this.$store.getters['map_store/graphData'].get(this.elem_id).data);

                if (this.scatterPlotX === "") {
                    this.scatterPlotX = keys[0];
                }
                if (this.scatterPlotY === "") {
                    this.scatterPlotY = keys[1];
                }
                this.init_d3_scatterplot_graph();
            }
        }
    },
    methods: {
        select() {
            this.minimized = !this.minimized;
        },
        async init_d3_reflectance_curve_graph() {
            while (!this.$store.getters['map_store/graphData'].get(this.elem_id)) {
                console.log("ITER", i);
                i++;

                await delay(500);

            }
            d3.select("#" + this.elem_id + '_dataviz').selectAll("g > *").remove();
            d3.select("#" + this.elem_id + '_dataviz').select('svg').remove();
            if (this.$store.getters['map_store/graphData'].get(this.elem_id)) {
                // set the dimensions and margins of the graph
                var margin = {top: 30, right: 30, bottom: 70, left: 60},
                    width = 460 - margin.left - margin.right,
                    height = 400 - margin.top - margin.bottom;

                // append the svg object to the body of the page
                var svg = d3.select("#" + this.elem_id + '_dataviz')
                    .append("svg")
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform",
                        "translate(" + margin.left + "," + margin.top + ")");

                var dataArray = this.$store.getters['map_store/graphData'].get(this.elem_id).data;

                // X axis
                var x = d3.scaleBand()
                    .range([0, width])
                    .domain(dataArray.map(function (d) {
                        return d.key;
                    }))
                    .padding(0.2);
                svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(x))
                    .selectAll("text")
                    .attr("transform", "translate(-10,0)rotate(-45)")
                    .style("text-anchor", "end");

                let maxY = 0;
                for (let j = 0; j < dataArray.length; j++) {
                    if (parseFloat(dataArray[j].value) > maxY) {
                        maxY = parseFloat(dataArray[j].value);
                    }
                }
                // // Add Y axis
                var y = d3.scaleLinear()
                    .domain([0, maxY + maxY * 0.1])
                    .range([height, 0]);
                svg.append("g")
                    .call(d3.axisLeft(y));

                // Add the line
                svg.append("path")
                    .datum(dataArray)
                    .attr("fill", "none")
                    .attr("stroke", "steelblue")
                    .attr("stroke-width", 1.5)
                    .attr("d", d3.line()
                        .x(function (d) {
                            return x(d.key)
                        })
                        .y(function (d) {
                            return y(d.value)
                        })
                    )
            }
        },
        async init_d3_barplot_graph() {
            while (!this.$store.getters['map_store/graphData'].get(this.elem_id)) {
                console.log("ITER", i);
                i++;

                await delay(500);

            }
            d3.select("#" + this.elem_id + '_dataviz').selectAll("g > *").remove();
            d3.select("#" + this.elem_id + '_dataviz').select('svg').remove();
            if (this.$store.getters['map_store/graphData'].get(this.elem_id)) {
                // set the dimensions and margins of the graph
                var margin = {top: 30, right: 30, bottom: 70, left: 60},
                    width = 460 - margin.left - margin.right,
                    height = 400 - margin.top - margin.bottom;

                // append the svg object to the body of the page
                var svg = d3.select("#" + this.elem_id + '_dataviz')
                    .append("svg")
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform",
                        "translate(" + margin.left + "," + margin.top + ")");

                var dataArray = this.$store.getters['map_store/graphData'].get(this.elem_id).data;

                // X axis
                var x = d3.scaleBand()
                    .range([0, width])
                    .domain(dataArray.map(function (d) {
                        return d.key;
                    }))
                    .padding(0.2);
                svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(x))
                    .selectAll("text")
                    .attr("transform", "translate(-10,0)rotate(-45)")
                    .style("text-anchor", "end");

                let maxY = 0;
                for (let j = 0; j < dataArray.length; j++) {
                    if (parseFloat(dataArray[j].value) > maxY) {
                        maxY = parseFloat(dataArray[j].value);
                    }
                }

                // // Add Y axis
                var y = d3.scaleLinear()
                    .domain([0, maxY + maxY * 0.1])
                    .range([height, 0]);
                svg.append("g")
                    .call(d3.axisLeft(y));

                // Bars
                svg.selectAll("mybar")
                    .data(dataArray)
                    .enter()
                    .append("rect")
                    .attr("x", function (d) {
                        return x(d.key);
                    })
                    .attr("y", function (d) {
                        return y(d.value);
                    })
                    .attr("width", x.bandwidth())
                    .attr("height", function (d) {
                        return height - y(d.value);
                    })
                    .attr("fill", "#69b3a2")
            }
        },
        async init_d3_histogram_graph() {
            while (!this.$store.getters['map_store/graphData'].get(this.elem_id)) {
                console.log("ITER", i);
                i++;

                await delay(500);

            }

            d3.select("#" + this.elem_id + '_dataviz').selectAll("g > *").remove();
            d3.select("#" + this.elem_id + '_dataviz').select('svg').remove();
            if (this.$store.getters['map_store/graphData'].get(this.elem_id)) {
                // set the dimensions and margins of the graph
                var margin = {top: 30, right: 30, bottom: 70, left: 60},
                    width = 460 - margin.left - margin.right,
                    height = 400 - margin.top - margin.bottom;

                // append the svg object to the body of the page
                var svg = d3.select("#" + this.elem_id + '_dataviz')
                    .append("svg")
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform",
                        "translate(" + margin.left + "," + margin.top + ")");

                var dataArray = this.$store.getters['map_store/graphData'].get(this.elem_id).data;

                // X axis: scale and draw:
                var x = d3.scaleLinear()
                    .domain([0, d3.max(dataArray["channel0"], function (d) {
                        return +d
                    })])     // can use this instead of 1000 to have the max of data: d3.max(data, function(d) { return +d.price })
                    .range([0, width]);
                svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(x));


                // set the parameters for the histogram
                var histogram = d3.histogram()
                    .value(function (d) {
                        return d;
                    })   // I need to give the vector of value
                    .domain(x.domain())  // then the domain of the graphic
                    .thresholds(x.ticks(70)); // then the numbers of bins

                // And apply this function to data to get the bins
                var bins = histogram(dataArray["channel0"]);

                // Y axis: scale and draw:
                var y = d3.scaleLinear()
                    .range([height, 0]);
                y.domain([0, d3.max(bins, function (d) {
                    return d.length;
                })]);   // d3.hist has to be called before the Y axis obviously
                svg.append("g")
                    .call(d3.axisLeft(y));

                // append the bar rectangles to the svg element
                svg.selectAll("rect")
                    .data(bins)
                    .enter()
                    .append("rect")
                    .attr("x", 1)
                    .attr("transform", function (d) {
                        return "translate(" + x(d.x0) + "," + y(d.length) + ")";
                    })
                    .attr("width", function (d) {
                        return x(d.x1) - x(d.x0) - 1;
                    })
                    .attr("height", function (d) {
                        return height - y(d.length);
                    })
                    .style("fill", "#69b3a2")

            }
        },

        async init_d3_scatterplot_graph() {
            while (!this.$store.getters['map_store/graphData'].get(this.elem_id)) {
                console.log("ITER", i);
                i++;

                await delay(500);

            }
            d3.select("#" + this.elem_id + '_dataviz').selectAll("g > *").remove();
            d3.select("#" + this.elem_id + '_dataviz').select('svg').remove();
            if (this.$store.getters['map_store/graphData'].get(this.elem_id)) {

                // set the dimensions and margins of the graph
                var margin = {top: 30, right: 30, bottom: 70, left: 60},
                    width = 460 - margin.left - margin.right,
                    height = 400 - margin.top - margin.bottom;

                // append the svg object to the body of the page
                var svg = d3.select("#" + this.elem_id + '_dataviz')
                    .append("svg")
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform",
                        "translate(" + margin.left + "," + margin.top + ")");

                //Read the data
                var dataArray = this.$store.getters['map_store/graphData'].get(this.elem_id).data;


                // Add X axis
                var x = d3.scaleLinear()
                    .domain([0, d3.max(dataArray[this.scatterPlotX], function (d) {
                        return +d
                    })])
                    .range([0, width]);
                svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(x));

                // Add Y axis
                var y = d3.scaleLinear()
                    .domain([0, d3.max(dataArray[this.scatterPlotY], function (d) {
                        return +d
                    })])
                    .range([height, 0]);
                svg.append("g")
                    .call(d3.axisLeft(y));

                let data = [];
                for (let i = 0; i < dataArray[this.scatterPlotX].length; i++) {
                    data.push({"x0": dataArray[this.scatterPlotX][i], "x1": dataArray[this.scatterPlotY][i]});
                }

                // Add dots
                svg.append('g')
                    .selectAll("dot")
                    .data(data)
                    .enter()
                    .append("circle")
                    .attr("cx", function (d) {
                        return x(d.x0);
                    })
                    .attr("cy", function (d) {
                        return y(d.x1);
                    })
                    .attr("r", 1.5)
                    .style("fill", "#69b3a2")

            }
        }

    },

    async mounted() {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        while (!this.elem_id) {
            await delay(150);
        }
        const dragItem = document.querySelector("#" + this.elem_id);
        const container = document.querySelector("#map");

        let active = false;
        let currentX = document.body.clientWidth * 0.2;
        let currentY = document.body.clientHeight * 0.05;
        let initialX;
        let initialY;
        let xOffset = document.body.clientWidth * 0.2;
        let yOffset = document.body.clientHeight * 0.05;

        container.addEventListener("touchstart", dragStart, false);
        container.addEventListener("touchend", dragEnd, false);
        container.addEventListener("touchmove", drag, false);

        container.addEventListener("mousedown", dragStart, false);
        container.addEventListener("mouseup", dragEnd, false);
        container.addEventListener("mousemove", drag, false);

        function dragStart(e) {
            if (e.type === "touchstart") {
                initialX = e.touches[0].clientX - xOffset;
                initialY = e.touches[0].clientY - yOffset;
            } else {
                initialX = e.clientX - xOffset;
                initialY = e.clientY - yOffset;
            }

            if (e.target === dragItem) {
                active = true;
            }
        }

        function dragEnd(e) {
            initialX = currentX;
            initialY = currentY;

            active = false;
        }

        function drag(e) {
            if (active) {

                e.preventDefault();

                if (e.type === "touchmove") {
                    currentX = e.touches[0].clientX - initialX;
                    currentY = e.touches[0].clientY - initialY;
                } else {
                    currentX = e.clientX - initialX;
                    currentY = e.clientY - initialY;
                }

                xOffset = currentX;
                yOffset = currentY;

                setTranslate(currentX, currentY, dragItem);
            }
        }

        function setTranslate(xPos, yPos, el) {
            el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
        }

        // do not pop up new graphs at top left corner but with some offset
        setTranslate(currentX, currentY, dragItem);

        // GRAPH INIT

        if (this.pointFeatureOn) {
            // singleclicks on map
            this.vizualizationType = "Default";
            this.init_d3_barplot_graph();
        } else {
            // analyze selection in drawbox comp
            this.vizualizationType = "Histogram";
            this.init_d3_histogram_graph();
        }


    }
}
</script>

<style scoped>


.drag_item {
    width: auto;
    height: 50px;
    background-color: greenyellow;
    border: 20px solid;
    border-radius: 50%;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(to left, #00C853, #B2FF59);
    touch-action: none;
    user-select: none;
    position: relative;
}

.drag_item_maximized {
    width: 45%;
    height: 40%;
    background-color: white;
    border: 30px solid;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(to left, #00C853, #B2FF59);
    touch-action: none;
    user-select: none;
    position: relative;

}

.drag_item:active {
    background-color: greenyellow;
}

.drag_item:hover {
    cursor: pointer;
}

.vizualization_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.vizualization_chart {
    grid-column-start: 0;
    grid-column-end: 1;
}

.vizualization_settings {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-column-start: 1;
}

.vizualization_btn {
    float: right;
    border-radius: 0.2rem;
    border: none;
    background-color: greenyellow;
    height: 2rem;
    color: darkslategray;
}

.vizualization_btn:hover {
    background-color: green;
}

.vizualization_section_hdr{
    margin-top: 0.2rem;
    margin-bottom: 0.15rem;
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>