<template>
  <div class="drawer" style="overflow-y: auto">
    <div class="tile is-parent is-vertical has-text-left">
      <div class="tile is-child box">
        <h4 class="title is-5">Available Layers</h4>
      </div>
      <ul id="itemsAll">
        <li v-for="(item, index) in $store.getters.userLayers" :key="index" :data-id="index"
            style="overflow: hidden;">
          <div @dblclick="zoomLayer(index, item)" @click="selectLayer(index)">
            <p :class="{'selected_layer': $store.getters['map_store/getSelectedLayers'].includes(index)}"
               class="subtile is-6 is-child box">{{ item.name }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="tile is-parent is-vertical has-text-left">
      <div class="tile is-child box">
        <h4 class="title is-5">Active Layers</h4>
      </div>
      <ul id="itemsActive">
      </ul>
    </div>
  </div>
</template>

<script>

// Default SortableJS
import Sortable from 'sortablejs';
import "ol/ol.css";
import {fromLonLat} from "ol/proj";


export default {
  name: "LDMenu",
  data() {
    return {
      allLayers: null,
      activeLayers: null,
    }
  },
  methods: {
    setActiveLayers(layers) {
      this.$store.commit('map_store/UPDATE_ACTIVE_LAYERS', layers);
    },
    zoomLayer(index, layer) {
      // solve click
      if (this.$store.getters["map_store/getActiveLayers"].includes(index)) {
        let map = this.$store.getters["map_store/mapObject"];
        let bb = layer["latLonBoundingBox"];
        let centerPoint = [(bb["minx"] + bb["maxx"]) / 2, (bb["miny"] + bb["maxy"]) / 2];
        let lonlat = fromLonLat(centerPoint);

        let zoom = 9;
        if (Math.max(bb["maxx"] - bb["minx"], bb["maxy"] - bb["miny"]) < 0.5) {
          zoom = 15;
        }
        map.getView().animate({
          zoom: zoom,
          center: lonlat,
          duration: 600
        });
      }
    },
    selectLayer(index) {
      // User is able to select only active layers
      if (this.$store.getters["map_store/getActiveLayers"].includes(index)) {
        let currentI = this.$store.getters["map_store/getSelectedLayers"].indexOf(index);
        if (currentI !== -1) {
          this.$store.commit("map_store/DELETE_LAYER_FROM_SELECTED", currentI);
          if (this.$store.getters["map_store/getSelectedLayers"].length === 0) {
            this.$store.commit("map_store/UPDATE_ANALYTIC_VIEW");
          }
        } else {
          this.$store.commit("map_store/PUSH_LAYER_TO_SELECTED", index);
        }
      }

    }
  },

  mounted() {
    this.$store.commit("map_store/CLEAR_SELECTED_LAYERS");
    let iall = document.getElementById('itemsAll');
    let iacc = document.getElementById('itemsActive');
    const ourThis = this;
    this.allLayers = Sortable.create(iall, {
      group: 'shared',
      animation: 150,
      store: {
        set: function (sortable) {
          let inactiveLayers = sortable.toArray().map(elem => parseInt(elem));
          // Disable 'click-state' on element that have been moved to Layers(inactive on map) section
          ourThis.$store.commit('map_store/REMOVE_SELECTED_LABELS', inactiveLayers);
          if (ourThis.$store.getters["map_store/getAnalyticView"]) {
            ourThis.$store.commit('map_store/UPDATE_ANALYTIC_VIEW');
          }
        }
      }
    });

    this.activeLayers = Sortable.create(iacc, {
      group: 'shared',
      animation: 150,
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: function (sortable) {
          ourThis.setActiveLayers(sortable.toArray().map(elem => parseInt(elem)));
          ourThis.$store.dispatch('map_store/update_map_layers');
        }
      }
    });
  }
}
</script>

<style scoped>

.selected_layer {
  background-color: #bada55;
}

/*#itemsActive > li > div > p {*/
/*  background-color: gray;*/
/*  border-radius: 10px;*/
/*}*/
</style>