<!-- ROOT SENTINEL 2 CLOUDLESS COMPONENT -->
<template>
  <modal>
    <template v-slot:header>
      <div class="content">
        <h2 id="title-start">
          {{ $t("sentinel.gen_cloudless") }}
        </h2>
      </div>
    </template>
    <template v-slot:body>
      <!-- AREA -->
      <div class="level-left">
        <h3 id="area-title" class="sentinel-titles title is-5">
          {{ $t("sentinel.area") }}
        </h3>
      </div>
      <div class="tabs">
        <ul>
          <li
            id="polygon2"
            :class="[currentTab === 'polygon' ? 'is-active' : '']"
            @click="currentTab = 'polygon'"
          >
            <a>Polygon</a>
          </li>
          <li
            id="file-area"
            :class="[currentTab === 'file' ? 'is-active' : '']"
            @click="currentTab = 'file'"
          >
            <a>{{ $t("file") }}</a>
          </li>
        </ul>
      </div>

      <!-- Polygon block -->
      <div class="block" v-show="currentTab === 'polygon'">
        <input class="input" type="text" :placeholder="polygon" readonly />
        <p class="help level-left is-danger" v-show="polygon == null">
          {{ $t("sentinel.no_polygon") }}
        </p>
        <p class="help level-left is-success" v-show="polygon != null">
          {{ $t("sentinel.polygon_available") }}
        </p>
      </div>
      <!-- File block -->
      <table
        v-show="currentTab === 'file'"
        class="table is-hoverable is-fullwidth"
        style="text-align:left"
      >
        <tbody>
          <tr
            v-for="(item, index) in availableFiles"
            :key="index"
            @click="selectedFile = index"
            :class="[index === selectedFile ? 'is-selected' : '']"
          >
            <td>{{ item.name }}</td>
          </tr>
        </tbody>
      </table>
      <hr class="solid" />

      <!-- TIME AND CLOUD COVERAGE -->
      <div class="block">
        <div class="columns">
          <!-- TIME COLUMN -->
          <div class="column">
            <div class="block level-left">
              <h3 class="sentinel-titles title is-5">
                {{ $t("sentinel.time") }}
              </h3>
            </div>
            <label class="label level-left">{{ $t("sentinel.from") }}</label>
            <Datepicker v-model="dateFrom" placeholder="Please select a date" />
            <label class="label level-left">{{ $t("sentinel.to") }}</label>
            <Datepicker v-model="dateTo" placeholder="NOW" />
          </div>
          <!-- CLOUD COLUMN -->
          <div class="column">
            <div class="block level-left">
              <h3 class="sentinel-titles title is-5">Cloud coverage</h3>
            </div>
            <div class="block">
              <p>{{ cloudMin + "%" }} {{$t("sentinel.to")}}  {{ cloudMax + "%" }}</p>
              <div id="sentinel-slider"></div>
            </div>
          </div>
        </div>
      </div>
      <hr class="solid" />
      <!-- SPATIAL RESOLUTION -->

      <div class="block">
        <div class="columns">
          <div class="column">
            <div class="block level-left">
              <h3 class="sentinel-titles title is-5">{{ $t("sentinel.spatial_res") }}</h3>
            </div>
            <div class="select is-info is-normal">
              <select v-model="spatialResolution">
                <option value="null">{{ $t("sentinel.select_res") }}</option>
                <option value="10">10m</option>
                <option value="20">20m</option>
                <option value="60">60m</option>
              </select>
            </div>
          </div>
          <div class="column">
            <div class="block level-left">
              <h3 class="sentinel-titles title is-5">{{$t("sentinel.bands")}}</h3>
            </div>
            <div class="tags">
              <span
                :class="
                  'tag ' +
                    (selectedSentinelBands.includes(item) ? 'is-warning' : '')
                "
                v-for="(item, index) in sentinelBands"
                :key="index"
                @click="selectBand(item)"
              >
                {{ item }}
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="column">
                    <div class="block level-left">
                        <h3 class="sentinel-titles title is-5">Pre-computed bands</h3>
                    </div>
                    <div class="tags">
                            <span :class="'tag ' + (selectedPrecomputedBands.includes(property) ? 'is-warning' : '')"
                                  v-for="(item, property, index) in availablePreComputed" :key="index"
                                  @click="selectPreComputedBand(property)">
                                {{ property }}
                            </span>
                    </div>
                </div> -->
      </div>
      <hr class="solid" />
      <!-- ALGORITHM -->
      <div class="block level-left">
        <h3 class="sentinel-titles title is-5">{{ $t("sentinel.cloudless_algo") }}</h3>
      </div>
      <div class="block">
        <div class="columns">
          <div class="column">
            <input
              type="radio"
              id="one"
              value="pixel"
              v-model="algorithmType"
            />
            <label for="one"> Per-Pixel </label>
          </div>
          <div class="column">
            <input type="radio" id="two" value="tile" v-model="algorithmType" />
            <label for="two"> Per-Tile </label>
          </div>
        </div>
      </div>

      <p v-if="algorithmType === 'pixel'">{{ $t("sentinel.perpixel_pick") }}</p>
      <div class="block select is-info" v-if="algorithmType === 'pixel'">
        <select class="select" v-model="detector">
          <option value="ndvi">NDVI</option>
          <option value="median">Median</option>
          <option value="s2cloudless">S2Cloudless</option>
        </select>
      </div>

      <p v-if="algorithmType === 'tile'">
        {{ $t("sentinel.pertile_pick") }}
      </p>
      <div class="block select is-info" v-if="algorithmType === 'tile'">
        <select class="select" v-model="tileSize">
          <option v-if="spatialResolution !== '60'" value="45">2x2</option>
          <option v-if="spatialResolution !== '60'" value="18">5x5</option>
          <option value="10">10x10</option>
          <option value="5">20x20</option>
        </select>
      </div>

      <p v-if="algorithmType === 'tile'">
        {{ $t("sentinel.pertile_detector") }}
      </p>
      <div class="block select is-info" v-if="algorithmType === 'tile'">
        <select class="select" v-model="detector">
          <option value="scl">SCL</option>
          <option value="s2cloudless">S2Cloudless</option>
          <option value="maxndvi">Max NDVI</option>
        </select>
      </div>

      <p v-if="detector === 'ndvi'">
        {{ $t("sentinel.perpix_ndvi") }}
      </p>
      <p v-if="detector === 'median'">
        {{ $t("sentinel.perpix_median") }} 
      </p>
      <p v-if="detector === 'scl'">
        {{ $t("sentinel.perpix_scl") }}
      </p>
      <p v-if="detector === 's2cloudless'">
        {{ $t("sentinel.perpix_mm") }}
      </p>
      <p v-if="detector === 'maxndvi'">
        This method takes the tile with the highest average of NDVI
      </p>

      <hr class="solid" />
      <!-- DISABLE CLIP RESULT -->
      <!-- <div class="block level-left">
        <label class="checkbox sentinel-titles title is-5">
          Clip result
          <input type="checkbox" />
        </label>
      </div> -->
    </template>
    <template v-slot:footer>
      <div class="columns">
        <div class="column">
          <button class="button is-danger" @click="$emit('close')">
            Close
          </button>
        </div>
        <div class="column">
          <div class="field has-addons">
            <div class="control">
              <button class="button is-success" @click="createJob">
                {{ $t("create_job") }}
              </button>
            </div>
            <div class="control">
              <input
                class="input"
                maxlength="30"
                type="text"
                v-model="name"
                placeholder="Name"
              />
            </div>
          </div>
        </div>
      </div>
      <v-tour name="myTour" :steps="steps"></v-tour>
    </template>
  </modal>
</template>

<script>
import modal from "@/components/tiles/modal";
import Datepicker from "vue3-datepicker";
import noUiSlider from "nouislider/dist/nouislider";
import { transform } from "ol/proj";
import { API } from "@/assets/js/axio-api";

export default {
  name: "SentinelModal",
  components: {
    modal,
    Datepicker,
  },
  data() {
    return {
      showModal: false,
      // date
      dateFrom: null,
      dateTo: null,
      finalDate: null,
      // area
      currentTab: "polygon",
      polygon: null,
      availableFiles: this.$store.getters.getUserFilesWithExtension([
        "geojson",
        "shp",
      ]), // files that can be used to define an area
      selectedFile: -1, // final selection
      // algo
      algorithmType: null,
      tileSize: null,
      detector: null,
      // clouds
      cloudMin: 0,
      cloudMax: 80,
      // general
      name: "",
      spatialResolution: null,
      sentinelBands: [
        "B01",
        "B02",
        "B03",
        "B04",
        "B05",
        "B06",
        "B07",
        "B08",
        "B8A",
        "B09",
        "B11",
        "B12",
        "AOT",
        "SCL",
      ],
      availablePreComputed: {
        "Vegetation Index": ["B8A", "B04"],
        Agriculture: ["B11", "B8A", "B04"],
        "Color Infrared": ["B08", "B04", "B03"],
        "Moisture Index": ["B8A", "B11"],
      },
      selectedSentinelBands: [],
      selectedPrecomputedBands: [],
      // tour
      steps: [
        {
          target: "#title-start",
          header: {
            title: "Sentinel-2 cloudless",
          },
          content:
            "Welcome to <strong>Sentinel-2</strong> job configurator.<br>This tour shows you how to properly configure a job and what each option means.",
        },
        {
          target: "#area-title",
          header: {
            title: "Defining area",
          },
          content:
            "In this section you pick the way you want to define the area of interest.",
        },
        {
          target: "#polygon2",
          content:
            "The default way how to define area is drawing a polygon on the map. Which will be automatically detected and used for the job.",
        },
        {
          target: "#file-area",
          content:
            "Another way is with file, currently supported files: GeoJSON, XX.",
        },
      ],
    };
  },

  watch: {
    // pre-select bands based on resolution
    spatialResolution: function(resolution, _) {
      switch (resolution) {
        case "10":
          this.selectedSentinelBands = ["B02", "B03", "B04", "B08", "AOT"];
          break;
        case "20":
          this.selectedSentinelBands = [
            "B02",
            "B03",
            "B04",
            "B05",
            "B06",
            "B07",
            "B8A",
            "B11",
            "B12",
            "AOT",
            "SCL",
          ];
          break;
        case "60":
          this.selectedSentinelBands = [
            "B01",
            "B02",
            "B03",
            "B04",
            "B05",
            "B06",
            "B07",
            "B8A",
            "B09",
            "B11",
            "B12",
            "AOT",
          ];
          break;
        default:
          this.selectedSentinelBands = [];
          break;
      }
    },
  },

  methods: {
    selectBand(item) {
      if (this.selectedSentinelBands.includes(item)) {
        let i = this.selectedSentinelBands.indexOf(item);
        this.selectedSentinelBands.splice(i, 1);
      } else {
        this.selectedSentinelBands.push(item);
      }
    },

    selectPreComputedBand(item) {
      // Selecting pre-computed bands is implementing active check whether the necessary bands are selected
      let localCheck = function(b, selected) {
        let mySet = new Set(b);
        selected.forEach((i) => mySet.delete(i));
        return mySet;
      };
      let nec_bands;
      switch (item) {
        case "Vegetation Index":
          nec_bands = localCheck(
            this.availablePreComputed["Vegetation Index"],
            this.selectedSentinelBands
          );
          if (nec_bands.size > 0) {
            alert(
              "To compute vegetation index please select " +
                Array.from(nec_bands).join(" ") +
                " band(s)."
            );
            return;
          }
          break;
        case "Agriculture":
          nec_bands = localCheck(
            this.availablePreComputed["Agriculture"],
            this.selectedSentinelBands
          );
          if (nec_bands.size > 0) {
            alert(
              "To compute Agriculture please select " +
                Array.from(nec_bands).join(" ") +
                " band(s)."
            );
            return;
          }
          break;
        case "Color Infrared":
          nec_bands = localCheck(
            this.availablePreComputed["Color Infrared"],
            this.selectedSentinelBands
          );
          if (nec_bands.size > 0) {
            alert(
              "To compute Color Infrared please select " +
                Array.from(nec_bands).join(" ") +
                " band(s)."
            );
            return;
          }
          break;
        case "Moisture Index":
          nec_bands = localCheck(
            this.availablePreComputed["Moisture Index"],
            this.selectedSentinelBands
          );
          if (nec_bands.size > 0) {
            alert(
              "To compute Moisture Index please select " +
                Array.from(nec_bands).join(" ") +
                " band(s)."
            );
            return;
          }
          break;
        default:
          return;
      }
      if (this.selectedPrecomputedBands.includes(item)) {
        let i = this.selectedPrecomputedBands.indexOf(item);
        this.selectedPrecomputedBands.splice(i, 1);
      } else {
        this.selectedPrecomputedBands.push(item);
      }
    },

    selectFile(index) {
      this.selectedFile = index;
    },

    formatDates() {
      if (this.dateFrom == null) {
        return null;
      }
      // slice(-2) -> 010 -> 10
      let from =
        this.dateFrom.getFullYear() +
        "-" +
        ("0" + (this.dateFrom.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + this.dateFrom.getDate()).slice(-2);
      let to =
        this.dateTo != null
          ? this.dateTo.getFullYear() +
            "-" +
            ("0" + (this.dateTo.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + this.dateTo.getDate()).slice(-2)
          : "NOW";
      // append the time we want this to be always 00:00:00.000Z for from and 23:59:59.999Z for to
      this.finalDate =
        "[" +
        from +
        "T00:00:00.000Z" +
        " TO " +
        (to !== "NOW" ? to + "T23:59:59.999Z" : to) +
        "]";
    },

    createJob() {
      if (!this._checkArea()) return;
      this.areaType = this.currentTab;

      // Time check -- someone in the future, extend the check
      if (this.dateFrom == null) {
        alert("Please select a valid time period.");
        return; // bad date
      }
      this.formatDates();

      // SPATIAL RESOLUTION AND BANDS
      if (this.spatialResolution == null) {
        alert("Please select spatial resolution");
        return;
      }

      // Sleceting bands and checking minimum requirements -- min. req. B03, B04, B02 ? --> NO
      if (this.selectedSentinelBands.length === 0) {
        alert("Please select some bands");
        return;
      }

      // TODO: EDIT INTERFACE, ADD RGB TO PRECOMPUTED AND DELETE THIS
      if (
        !this.selectedSentinelBands.includes("B03") ||
        !this.selectedSentinelBands.includes("B02") ||
        !this.selectedSentinelBands.includes("B04")
      ) {
        alert("Bands B02, B03, B04 are mandatory please select them");
        return;
      }

      // Picking algorithm logic
      if (this.algorithmType == null) {
        alert("Please select algorithm type.");
        return;
      }
      if (this.algorithmType === "tile" && this.tileSize == null) {
        alert("Please select tile size.");
        return;
      }

      // TODO: WE NO LONGER NEED THIS --- WILL BE DELETED
      if (this.algorithmType === "pixel") {
        if (!this.selectedSentinelBands.includes("B8A"))
          this.selectedSentinelBands.push("B8A");
        if (!this.selectedSentinelBands.includes("B04"))
          this.selectedSentinelBands.push("B04");
      }

      // NDVI PER-TILE DOESNT REQUIRE THIS BUT STAYS FOR A WHILE
      if (
        this.algorithmType === "tile" &&
        !this.selectedSentinelBands.includes("SCL")
      ) {
        this.selectedSentinelBands.push("SCL");
      }

      if (this.detector == null) {
        alert("Please select analysis");
        return;
      }

      if (this.name === "") {
        alert("Please provide a name.");
        return;
      }

      this.registerJob();
      this.$store.dispatch("periodicallyFetchJobs", Date.now());
    },

    registerJob() {
      // SUM UP SCREEN ?
      if (!confirm("Send job ?")) return;

      API.post("sentinel/create/", {
        areaType: this.currentTab, // polygon or file
        area:
          this.currentTab === "polygon"
            ? this.polygon
            : this.availableFiles[this.selectedFile].name,
        spatialResolution: parseInt(this.spatialResolution),
        bands: this.selectedSentinelBands,
        time: this.finalDate,
        algorithm: this.algorithmType,
        tileSize: this.tileSize ? this.tileSize : 1,
        name: this.name,
        cloudCoverage: [this.cloudMin, this.cloudMax],
        preComputedBands: this.selectedPrecomputedBands,
        detector: this.detector,
      })
        .then((response) => {
          // TODO: MAKE USE OF FIRST CHECK
        })
        .catch((err) => {
          // SAME AS ABOVE
        });
    },

    ////////////////////// PRIVATE //////////////////////

    _checkArea() {
      switch (this.currentTab) {
        case "polygon":
          if (this.polygon == null) {
            alert("Please draw a polygon.");
            return false;
          }
          break;
        case "file":
          if (this.selectedFile === -1) {
            alert("Please select a file");
            return false;
          }
          break;
      }
      return true;
    },
  },

  created() {
    // Get polygon and pre-process it
    let features = this.$store.getters["map_store/getSource"].getFeatures();
    if (features.length === 0) return;
    let coords = features[0].getGeometry().flatCoordinates;
    let result = [];
    for (let i = 0; i < coords.length; i += 2) {
      result.push(
        transform([coords[i], coords[i + 1]], "EPSG:900913", "EPSG:4326")
      );
    }
    this.polygon = result;
  },

  mounted() {
    // Mount slider for cloud coverage editing
    let slider = document.getElementById("sentinel-slider");
    noUiSlider.create(slider, {
      start: [this.cloudMin, this.cloudMax],
      connect: true,
      step: 1,
      range: {
        min: 0,
        max: 100,
      },
    });
    let my_this = this;
    slider.noUiSlider.on("update", function(values) {
      my_this.cloudMin = Math.trunc(values[0]);
      my_this.cloudMax = Math.trunc(values[1]);
    });

    // todo after its done append -> !
    if (localStorage.getItem("sentinel-tour")) {
      localStorage.setItem("sentinel-tour", true);
      this.$tours["myTour"].start();
    }
  },
};
</script>

<style>
.sentinel-titles {
  color: #7a7a7a !important;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

hr.solid {
  border-top: 1px solid #bbb;
}
</style>
