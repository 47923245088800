<template>
    <div class="floating-panel-wrap">
        <div class="floating-panel">

        </div>
    </div>
</template>

<script>
export default {
    name: "RPanel",
};
</script>

<style scoped>

.floating-panel-wrap {
    z-index: 99;
    height: 100%;
    width: 340px;
    display: flex;
    transform: translateX(0%);
    background: transparent;
    position: absolute;
    right: 3%;
    pointer-events: none;
}

.floating-panel {
    background-color: #ffebebeb;
    width: 100%;
    height: 100;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 10px;
}
</style>