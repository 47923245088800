<template>
    <div class="drawer">
        <p class="menu-label">Drawing and Layer actions</p>
        <form class="form-inline">
            <label for="type">Geometry type: &nbsp;</label>
            <select class="form-control mr-2 mb-2 mt-2" id="type" v-model="selectedDrawing">
                <option value="Point">Point</option>
                <option value="LineString">LineString</option>
                <option value="Polygon">Polygon</option>
                <option value="Circle">Circle</option>
                <option value="Box">Box</option>
                <option value="None">None</option>
            </select>
            <input class="form-control mr-2 mb-2 mt-2" type="button" value="Undo" id="undo" @click="undo">
            <input class="form-control mr-2 mb-2 mt-2" type="button" value="Clear canvas" @click="clearCanvas">
        </form>
        <input type="checkbox" id="checkbox" v-model="freeDrawing">
        <label for="checkbox"> Freehand drawing</label>
        <br>
        <button class="button" @click="cropLayers()">Crop layer</button>
        <button class="button" @click="resetLayers()">Reset layer</button>
        <p class="menu-label">Sentinel 2</p>
        <button class="button" @click="$emit('showSentinelModal')">Create Sentinel Job</button>
        <br>
        <p class="menu-label">Color map</p>
        <button class="button"
                v-show="$store.getters['map_store/getSelectedLayers'].length === 1 && !$store.getters['map_store/getAnalyticView']"
                @click="$store.commit('map_store/UPDATE_ANALYTIC_VIEW')">Activate analytic view
        </button>
        <button class="button" v-show="$store.getters['map_store/getAnalyticView']"
                @click="$store.commit('map_store/UPDATE_ANALYTIC_VIEW')">Deactivate analytic view
        </button>
        <br>
        <select class="select" v-model="palette" v-if="$store.getters['map_store/getAnalyticView']" id="palette">
            <option>viridis</option>
            <option>inferno</option>
            <option>rainbow</option>
            <option>jet</option>
            <option>hsv</option>
            <option>hot</option>
            <option>cool</option>
            <option>spring</option>
            <option>summer</option>
            <option>autumn</option>
            <option>winter</option>
            <option>bone</option>
            <option>copper</option>
            <option>greys</option>
            <option>yignbu</option>
            <option>greens</option>
            <option>yiorrd</option>
            <option>bluered</option>
            <option>rdbu</option>
            <option>picnic</option>
            <option>portland</option>
            <option selected>blackbody</option>
            <option>earth</option>
            <option>electric</option>
            <option>magma</option>
            <option>plasma</option>
        </select>
        <br>
        <!-- WCS REQUESTS -->
        <button v-show="$store.getters['map_store/getSelectedLayers'].length === 1" @click="downloadSelection"
                class="button">Download selection
        </button>
        <button @click="analyzeSelection" v-show="$store.getters['map_store/getSelectedLayers'].length === 1"
                class="button">Analyze selection
        </button>

    </div>
</template>

<script>
import Draw from "ol/interaction/Draw";
import ol_filter_Crop from "ol-ext/filter/Crop";
import {transform, transformExtent} from "ol/proj";
import {getArea} from 'ol/sphere';
import {Polygon} from 'ol/geom';
import proj4 from 'proj4';
import {geoserverAPI} from "@/assets/js/axio-api";
import GeoTIFF, {fromUrl, fromBlob} from "geotiff";

export default {

    data() {
        return {
            selectedDrawing: 'None',
            freeDrawing: false,
            palette: 'yiorrd',
        }
    },

    watch: {
        /*
        *  When change is detected, last interaction is deleted from the map
        *  and new type of interaction is created.
        */
        selectedDrawing: function (newDraw, _) {
            console.log("SELECTED DRAWING w TYPE: " + newDraw + " AND FREEHAND: " + this.freeDrawing)
            this.$store.getters['map_store/mapObject'].removeInteraction(this.$store.getters['map_store/getDraw']);
            this.$store.dispatch('map_store/addInteraction', {type: newDraw, freeHand: this.freeDrawing});
        },
        freeDrawing: function (newFreeDrawing, _) {
            console.log("SELECTED DRAWING w TYPE: " + this.selectedDrawing + " AND FREEHAND: " + newFreeDrawing);
            this.$store.getters['map_store/mapObject'].removeInteraction(this.$store.getters['map_store/getDraw']);
            this.$store.dispatch('map_store/addInteraction', {type: this.selectedDrawing, freeHand: this.freeDrawing});
        },
        palette: function (newValue, _) {
            let olgt_s2map = this.$store.getters['map_store/getSliderino'];
            olgt_s2map.plotOptions.palette = newValue;
            olgt_s2map.redraw();
        },
    },

    methods: {

        _WcsUrl(maxArea) {
            let features = this.$store.getters["map_store/getSource"].getFeatures();

            if (features.length === 0) {
                alert("Please draw a polygon");
                return;
            }

            let geometry = features[0].getGeometry();

            if (!(geometry instanceof Polygon)) {
                alert("Drawn feature must be a polygon");
                return;
            }

            // km2 - MAX AREA
            let area = (Math.round((getArea(geometry) / 1000000) * 100) / 100);
            console.log("area of selection(km2): " + area);
            console.log("drawn polygon : \n" + JSON.stringify(geometry.getCoordinates()));

            if (area > maxArea) {
                alert(`Area too big, max area is ${maxArea}km2, your is ${area}km2`);
                return null;
            }

            // transform coords to crs of the layer, for now it's only UTM33
            let selectedLayer = this.$store.getters['map_store/getSelectedLayers'][0];
            // let layerObject = this.$store.getters['map_store/mapLayerObjects'][selectedLayer];
            let layerDescription = this.$store.getters.userLayers[selectedLayer];

            let coords = geometry.getCoordinates()[0]; // 2D array

            // extract minx, miny, maxx, maxy
            let [minx, maxx] = [coords[0][0], coords[0][0]];
            let [miny, maxy] = [coords[0][1], coords[0][1]];
            for (let i = 0; i < coords.length; ++i) {
                minx = minx > coords[i][0] ? coords[i][0] : minx;
                maxx = maxx < coords[i][0] ? coords[i][0] : maxx;
                miny = miny > coords[i][1] ? coords[i][1] : miny;
                maxy = maxy < coords[i][1] ? coords[i][1] : maxy;
            }
            // move this somewhere or add definitions here if we are going to use others
            proj4.defs("EPSG:32633", "+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
            let fromProj = new proj4.Proj('EPSG:900913');
            let toProj = new proj4.Proj('EPSG:32633');

            let minValues = proj4(fromProj, toProj, [minx, miny]);
            let maxValues = proj4(fromProj, toProj, [maxx, maxy]);

            return `/ows?service=WCS&version=2.0.1&request=GetCoverage&coverageId=${layerDescription.workspace}__${layerDescription.name}&subset=E(${minValues[0].toFixed(1)},${maxValues[0].toFixed(1)})&subset=N(${minValues[1].toFixed(1)},${maxValues[1].toFixed(1)})`;
        },

        async analyzeSelection() {
            let url = this._WcsUrl(10);
            if (url == null) {
                return;
            }
            let resp = await geoserverAPI(url, {responseType: 'blob',
                                                Authorization: "Basic " + window.btoa(this.$store.getters.userDetails.email + ":" + this.$store.getters.userDetails.uid)});

            const tiff = await fromBlob(resp.data);
            console.log(JSON.stringify(tiff));
            const image = await tiff.getImage();

            console.log(JSON.stringify(image));
            // basic image properties
            console.log("Width: " + image.getWidth());
            console.log("Height: " + image.getHeight());
            console.log("Tile width : " + image.getTileWidth());
            const data = await image.readRasters();
            // PLOT CHARTS ONTO GRAPH VIEW
            this.$store.commit('map_store/UPDATE_SHOW_GRAPH');
        },

        downloadSelection() {
            let url = this._WcsUrl(1000);
            if (url == null) {
                return;
            }
            // Build the wcs request
            geoserverAPI.get(url, {responseType: 'blob',
                                   Authorization: "Basic " + window.btoa(this.$store.getters.userDetails.email + ":" + this.$store.getters.userDetails.uid)})
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'slection.tif'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    console.log(err);
                });
        },


        cropLayers() {
            /*
            User is allowed to draw a shape that is used to crop the selected layers. Only one shape is allowed when performing such action. Consequently feature
            representing this shape is extracted from map's source object and it's bounding box as well (which is later used to minimize
            the amount of wms requests by constraining the extent of the layer being cropped).
            Filter is then created from this feature which is added to the map.
             */
            if ((this.$store.getters["map_store/getSource"].getFeatures().filter(feature => feature instanceof Draw)).length > 1) {
                alert("You can apply only one shape mask to map, clear the canvas first and then try again.");
            }
            let selectedLayerObjects = this.$store.getters["map_store/getSelectedLayers"].map(index => this.$store.getters["map_store/mapLayerObjects"][index]);

            let cropFeature = this.$store.getters["map_store/getSource"].getFeatures()[0];
            let featureBoundingBox = cropFeature.getGeometry().getExtent();
            let crop = new ol_filter_Crop({feature: cropFeature, inner: false});

            selectedLayerObjects.forEach(obj => {
                obj.addFilter(crop);
                obj.set('active', true);
                obj.setExtent(featureBoundingBox);
            });
            this.clearCanvas();
        },
        resetLayers() {
            let selectedLayerObjects = this.$store.getters["map_store/getSelectedLayers"].map(index => [index, this.$store.getters["map_store/mapLayerObjects"][index]]);
            selectedLayerObjects.forEach(obj => {
                let currentFilters = obj[1].getFilters();
                currentFilters.forEach(filter => obj[1].removeFilter(filter));
                // Retrieve origin bounding box from the raw JSON data
                let boundingBox = this.$store.getters.userLayers[obj[0]].latLonBoundingBox;
                obj[1].setExtent(transformExtent([boundingBox.minx, boundingBox.miny, boundingBox.maxx, boundingBox.maxy], "EPSG:4326", "EPSG:900913"));
                this.clearCanvas();
            });
        },


        undo() {
            this.$store.getters['map_store/getDraw'].removeLastPoint();
        },

        clearCanvas() {
            this.$store.commit('map_store/DELETE_DRAWING_LAYER');
            this.selectedDrawing = 'None';
            this.freeDrawing = false;
        },
    }

}
;
</script>

<style>
.drawer {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
}
</style>
