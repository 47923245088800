<template>
    <transition name="slide" mode="in-out">
        <RPanel v-if="hm"/>
    </transition>

    <Navbar @showSentinelModal="sentinelModal = true" />
    <MapComponent/>
     <!--
        Show logic should be moved one level up to generic modal, we use v-if on purpose since it re-initializes
        the component on toggle this is bad in case initial load is big and user is toggling modal very often
    -->
    <SentinelModal v-if="sentinelModal" @close="sentinelModal = false"/>
    
    <!-- FUTURE COMPONENT!! -->
    <transition name="slide-fade" mode="out-in">
        <div v-if="drawnPoly" class="toast">
            <button class="button" @click="sentinelModal=true">Create Sentinel Job</button>
        </div>
    </transition>
</template>

<script>
import MapComponent from "@/components/map_enviroment/MapComponent.vue";
import RDMenu from "@/components/RDMenu.vue";
import LDMenu from "@/components/LDMenu";
import SentinelModal from "@/components/SentinelModal";
import Navbar from "@/components/map_enviroment/Navbar";
import RPanel from "@/components/map_enviroment/RPanel";

export default {
    data() {
        return {
            sentinelModal: false,
            toast: false,
            sidePan: false,
        }
    },
    components: {
        MapComponent,
        Navbar,
        RDMenu,
        LDMenu,
        SentinelModal,
        RPanel
    },

    computed: {
        drawnPoly() {
            return this.$store.getters["map_store/getSource"] !== null &&
                this.$store.getters["map_store/getSource"].getFeatures().length > 0;
        },

        hm() {
            return this.$store.getters["map_store/getSource"] !== null &&
                this.$store.getters["map_store/getSource"].getFeatures().length > 0;
        }
    },


};
</script>

<style scoped>

.slide-enter-from, .slide-leave-to{
    transform: translateX(65%);
    opacity: 0;
}


.slide-leave-active,
.slide-enter-active {
    transition: all 1s ease;
}

.toast {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0.5rem 5rem 0rem 0rem;
    top: 0;
    right: 0;
    height: 50px;
    width: 300px;
    transform: translateY(1%); /*default state, opacity is also by def. 1 no need for enter-to and leave-from*/
    background: #ffebeb99;
    border-radius: 5px;
    /*transition: background-color 300ms ease-out;*/
    
}

.slide-fade-enter-from, .slide-fade-leave-to{
    transform: translateY(-120%);
    opacity: 0;
}


.slide-fade-leave-active,
.slide-fade-enter-active {
    transition: all .3s ease;
}
</style>
