<template>
    <div id="map">
        <DraggableMenu v-for="[key, value] in this.$store.getters['map_store/graphData']" :elem_id="key"/>


        <div class="ol-return-home"></div>
        <input
            v-if="$store.getters['map_store/getAnalyticView']"
            v-on:input="$store.commit('map_store/UPDATE_SLIDER_RANGE')"
            v-model="
                $store.getters['map_store/getSliderino'].plotOptions.domain[0]
            "
            placeholder="Low"
            type="number"
            style="bottom: 7%; position: absolute; z-index: 1000; left: 27%; width: 5%"
        />
        <div
            v-show="$store.getters['map_store/getAnalyticView']"
            style="width: 35%; bottom: 7%; position: absolute; z-index: 1000; left: 35%"
            id="slider"
        ></div>
        <div
            v-show="$store.getters['map_store/getSelectedLayers'].length > 0"
            style="width: 35%; bottom: 2%; position: absolute; z-index: 1000; left: 35%"
            id="opacitySlider"
        ></div>
        <input
            v-if="$store.getters['map_store/getAnalyticView']"
            v-on:input="$store.commit('map_store/UPDATE_SLIDER_RANGE')"
            v-model="
                $store.getters['map_store/getSliderino'].plotOptions.domain[1]
            "
            placeholder="High"
            type="number"
            style="bottom: 7%; position: absolute; z-index: 1000; right: 23%; width: 5%"
        />
    </div>
</template>

<script>
import router from "@/router";
import "ol/ol.css";
import {transformExtent} from "ol/proj";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import DraggableMenu from "@/components/DraggableMenu";

export default {
    name: "MapComponent.vue",
    components: {DraggableMenu},

    data() {
        return {
            email: null,
            uid: null
        }
    },

    mounted() {
        let data = this.$store.getters.userDetails;
        this.email = data.email;
        this.uid = data.uid;
        // Create map
        this.$store.dispatch("map_store/create_map");
        // Add navigator
        // this.$store.dispatch('map_store/createNavigator');
        // Add drawing features
        this.$store.dispatch("map_store/createDrawingInteraction");
        // Clear the array object to avoid duplicates when applications mounts the component more than one time
        this.$store.commit("map_store/DELETE_MAP_LAYER_OBJECTS");
        // Create ol-Layer objects from user's layers
        this.$store.getters.userLayers.forEach((layerJson) => {
            let tmp = this.createLayer(layerJson);
            // push iff createLayer is an actual layer, no errors during create process
            tmp && this.$store.commit("map_store/PUSH_NEW_MAP_LAYER", tmp);
        });
        this.$store.dispatch("fetchStyles");
    },

    methods: {
        xhrTileLoadFunction(tile, src) {
            let client = new XMLHttpRequest();
            client.responseType = 'blob';
            client.open('GET', src);
            client.setRequestHeader("Authorization", "Basic " + window.btoa(this.email + ":" + this.uid));
            client.onload = function () {
                tile.getImage().src = URL.createObjectURL(client.response);
            };
            client.send();
        },
        createLayer(layer, format = "png") {
            let ext = layer.latLonBoundingBox;
            try {
                let attributions = [];
                if (layer.attribution.href) {
                    attributions.push('<a href="' + layer.attribution.href + '">' + layer.attribution.title + '</a>');
                } else if (layer.attribution.title) {
                    attributions.push(layer.attribution.title);
                }
                if (layer.attribution.logoURL){
                    attributions.push('<img src="'+ layer.attribution.logoURL+'">');
                }

                return new TileLayer({
                    extent: transformExtent(
                        [ext.minx, ext.miny, ext.maxx, ext.maxy],
                        "EPSG:4326",
                        "EPSG:900913"
                    ),
                    source: new TileWMS({
                        attributions: attributions,
                        attributionsCollapsible: false,
                        url: "https://envision.cerit-sc.cz/geoserver/wms",
                        params: {
                            LAYERS: layer.workspace + ":" + layer.name,
                            FORMAT: "image/" + format,
                            TILED: true,
                            STYLES: layer.defaultStyle,
                            // EXAMPLE OF ENV PARAMETER: 'ENV': "color_0:#2140d9;color_1:#2140d9;color_2:#2140d9;quantity_0:45;opacity_2:0"
                        },
                        serverType: "geoserver",
                        tileLoadFunction: this.xhrTileLoadFunction,
                        crossOrigin: 'use-credentials'
                    }),
                });
            } catch (e) {
                console.log("Error creating layer...");
                console.log("Extent: ", ext);
            }
            return null;
        },
    },
};
</script>

<style scoped>
#map {
    height: 100%;
    width: 100%;
    /*margin-left: 3rem;*/
}

#return-home {
    margin-left: 3rem;
    display: block;
    padding: 0;
    color: white;
    font-size: 1.14em;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    height: 1.375em;
    width: 1.375em;
    line-height: 0.4em;
    background-color: rgba(0, 60, 136, 0.5);
    border: none;
    border-radius: 2px;
}

#locate-btn {
    position: fixed !important;
    top: 60% !important;
    left: 0.5em !important;
}

.ol-return-home {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
    position: fixed;
    left: 0.5em;
    top: 50%;
    z-index: 2;
}

.ol-locate-control {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
    position: fixed !important;
    top: 60% !important;
    left: 0.5em !important;
}

/*.locate {*/
/*    top: 60% !important;*/
/*    left: 0.5em !important;*/
/*    position: fixed !important;*/
/*}*/
</style>
